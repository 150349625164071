//
// select2.js
// Theme module
//
import $ from "jquery";


import slick from "slick-carousel";



function timeline() {

  if($(window).width() < 1088)
  {
    var slides_count = 2;
  } else {
    var slides_count = 6;
  }

  $(".loading-bar").slick({
    centerMode: true,
    // centerPadding: "80px",
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: slides_count,
    slidesToScroll: 3,
    focusOnSelect: true,
    asNavFor: ".labels"
  });

  $(".labels").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    draggable: false,
    asNavFor: ".loading-bar"
  });


}

window.timeline = timeline;
timeline();
