import Rails from "@rails/ujs";
import "jquery";
const nativeConfirm = Rails.confirm;
let __SkipConfirmation = false;

Rails.confirm = function (message, element) {
  if (__SkipConfirmation) {
    return true;
  }

  if (element.hasAttribute("data-confirm-dialog")) {
    return nativeConfirm(message, element);
  }

  function onConfirm() {
    __SkipConfirmation = true;
    element.click();
    __SkipConfirmation = false;
  }

  showDialog(element, onConfirm);

  return false;
};

function showDialog(element, onConfirm) {
  const message = element.getAttribute("data-confirm");
  let $link,
    $modal_html,
    modal_html,
    $urlRemote,
    title,
    isRemote,
    design,
    method;

  if (!message) {
    return true;
  }

  $urlRemote = $(element).attr("href");

  isRemote = $(element).data("remote");

  title = $(element).data("title");

  design = $(element).data("design");

  method = $(element).data("method");

  if (isRemote) {
    $link = $(element)
      .clone()
      .removeAttr("class")
      .removeAttr("data-confirm")
      .removeAttr("data-method")
      .removeAttr("data-url")
      .addClass("btn btn-primary text-white")
      .html("Yes")
      .attr({ "data-bs-dismiss": "modal", href: "#", "data-remote": isRemote });
  } else {
    $link = $(element)
      .clone()
      .removeAttr("class")
      .removeAttr("data-confirm")
      .removeAttr("data-method")
      .removeAttr("data-url")
      .addClass("btn btn-primary text-white")
      .html("Yes")
      .attr({ href: "#", "data-remote": isRemote });
  }

  modal_html = `<div class="modal modal-form modal-form-${design} fade" id="modalConfirm" tabindex="-1" aria-labelledby="modalConfirmLabel" aria-hidden="true">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header justify-content-center">
          <div class="col">
            <div class="content-modal-title">
              <h1 id="modalConfirmLabel" class="modal-title title-modal">${title}</h1>
            </div>

            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">&times;</button>
          </div>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label class='message-confirm'></label>
          </div>
        </div>
        <div class='modal-footer-btn text-center mb-5'>
          <button type='button' class='btn btn-default btn-gray btn-block my-3' data-bs-dismiss='modal'>No</button>\
        </div>
      </div>
    </div>
  </div>`;

  $modal_html = $(modal_html);
  $link = $link
    .removeClass("btn-primary")
    .addClass("btn btn-primary")
    .attr({ href: $urlRemote, "data-method": method });
  $modal_html.find(".message-confirm").html(message);
  $modal_html.find(".modal-footer-btn").append($link);
  $modal_html.modal("show");

  return false;
}
