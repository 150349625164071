import confetti from "canvas-confetti";
window.customconfetti = confetti;

const toggles = document.querySelectorAll("[data_confetti]");

toggles.forEach((toggle) => {
  toggle.onclick = function () {
    if (this.checked) {
      startConfetti(this, this.getAttribute("data_canvas"));
    }
  };
});

function startConfetti(element, canvas) {
  const rect = element.getBoundingClientRect();
  const center = {
    x: rect.left + rect.width / 2,
    y: rect.top + rect.height / 2,
  };
  const origin = {
    x: center.x / window.innerWidth,
    y: center.y / window.innerHeight,
  };

  // Canvas && confetti settings
  var myCanvas = document.getElementById(`${canvas}`);
  const defaults = {
    disableForReducedMotion: true,
  };
  var colors = ["#757AE9", "#28224B", "#EBF4FF"];
  var myConfetti = window.customconfetti.create(myCanvas, {});

  // Confetti function to be more realistic
  function fire(particleRatio, opts) {
    window.customconfetti(
      Object.assign({}, defaults, opts, {
        particleCount: Math.floor(100 * particleRatio),
      })
    );
  }
  // Finished state confetti
  setTimeout(() => {
    fire(0.25, {
      spread: 26,
      startVelocity: 10,
      origin,
      colors,
    });
    fire(0.2, {
      spread: 60,
      startVelocity: 20,
      origin,
      colors,
    });
    fire(0.35, {
      spread: 100,
      startVelocity: 15,
      decay: 0.91,
      origin,
      colors,
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 10,
      decay: 0.92,
      origin,
      colors,
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 20,
      origin,
      colors,
    });
  }, "100");
}
