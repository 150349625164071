//
// flatpickr.js
// Theme module
//

import flatpickr from 'flatpickr';
function setFlatpickr() {  
  const toggles = document.querySelectorAll('[data-flatpickr]');
  const on_change_allowed = ["minDate"];
  toggles.forEach((toggle) => {
    let options = toggle.dataset.flatpickr ? JSON.parse(toggle.dataset.flatpickr) : {};
    if (Object.keys(options).length > 0){
      const flatpickr_on_change = toggle.dataset.flatpickrOnChange ? JSON.parse(toggle.dataset.flatpickrOnChange) : {};
      if(Object.keys(flatpickr_on_change).length > 0 && on_change_allowed.includes(flatpickr_on_change.action)){
        options.onChange = function  a(selectedDates, dateStr, instance) { 
          $(`#${flatpickr_on_change.element}`)[0]._flatpickr.set(flatpickr_on_change.action, new Date(dateStr).fp_incr(flatpickr_on_change.fp_incr)) 
        }
      }
    }
    flatpickr(toggle, options);
  });
}


// Make available globally
window.flatpickr = flatpickr;
window.SetFlatpickr = setFlatpickr;
setFlatpickr()
