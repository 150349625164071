//
// wizard.js
// Dashkit module
//

import { Tab } from 'bootstrap';
function setWizard() {
  const toggles = document.querySelectorAll('[data-toggle="wizard"]');

  toggles.forEach(toggle => {
    toggle.addEventListener('click', function(e) {
      e.preventDefault();

      var tab_wizard = $('form').find(".show").attr("id");
      if ($('#'+tab_wizard+' .is-invalid').length == 0){
        // Toggle new tab
        const tab = new Tab(toggle);

        tab.show();

        // Remove active state
        toggle.classList.remove('active');
      }
    });
  });
}


window.setWizard = setWizard;
setWizard()